import React, { Component } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { APILINK } from "../../EndPoint";
import { Calendar, Alert, Spin } from "antd";
import CalenderDetails from "./CalenderDetails";
import moment from "moment";

const axiosApiInstance = axios.create();

class CalendarComponent extends Component {
  state = {
    daysData: [],
    meetings: [],
    quizes: [],
    value: moment(),
    selectedValue: moment(),
    eventsDays: [],
    selectedDateData: [],
    selectedDay: "",
    loading: false,
  };

  componentDidMount() {
    this.loadCalendarData();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevMonth = prevState.selectedValue.format("YYYY-MM");
    const currentMonth = this.state.selectedValue.format("YYYY-MM");
    
    if (prevMonth !== currentMonth) {
      this.loadCalendarData();
    }
  }

  loadCalendarData = async () => {
    this.setState({ loading: true });
    try {
      const selectedDate = this.state.selectedValue;
      const month = selectedDate.format("MM");
      const year = selectedDate.format("YYYY");

      const response = await axiosApiInstance.get(
        `${APILINK}/calender?month=${month}&year=${year}`
      );

      // Reset all data if we get the "No events" message
      if (response.data.message) {
        this.setState({
          daysData: [],
          meetings: [],
          quizes: [],
          eventsDays: [],
          selectedDateData: [],
        }, this.highlightSelectedEvents);
        return;
      }

      const { subject_rounds, meeting, quiz } = response.data;

      // Extract all event days for highlighting
      const eventsDays = [
        ...this.extractDays(subject_rounds, 'start_date'),
        ...this.extractDays(meeting, 'date'),
        ...this.extractDays(quiz, 'start_date')
      ];

      this.setState({
        daysData: subject_rounds || [],
        meetings: meeting || [],
        quizes: quiz || [],
        eventsDays,
        selectedDateData: [], // Reset selected data when changing months
      }, this.highlightSelectedEvents);
    } catch (error) {
      console.error("Failed to load calendar data:", error);
      // Reset data on error as well
      this.setState({
        daysData: [],
        meetings: [],
        quizes: [],
        eventsDays: [],
        selectedDateData: [],
      }, this.highlightSelectedEvents);
    } finally {
      this.setState({ loading: false });
    }
  };

  extractDays = (events, dateField) => {
    return events.map(event => {
      const dateString = event[dateField]?.date;
      return dateString ? parseInt(dateString.split("-")[2], 10) : null;
    }).filter(day => day !== null);
  };

  highlightSelectedEvents = () => {
    // First remove all highlights
    const dateElements = document.getElementsByClassName("ant-picker-calendar-date");
    Array.from(dateElements).forEach(element => {
      element.classList.remove("selectedEvents");
    });

    // Then add highlights only where needed
    const eventElements = document.getElementsByClassName("events");
    Array.from(eventElements).forEach((element, index) => {
      if (element?.childNodes.length) {
        dateElements[index]?.classList.add("selectedEvents");
      }
    });
  };

  filterEventsByDate = (selectedDay) => {
    if (!selectedDay) return [];
    
    const { daysData = [], meetings = [], quizes = [] } = this.state;

    const subjectEvents = Array.isArray(daysData) 
      ? daysData.filter(item => item?.start_date?.date === selectedDay)
      : [];

    const meetingEvents = Array.isArray(meetings)
      ? meetings.filter(item => item?.date?.date === selectedDay)
      : [];

    const quizEvents = Array.isArray(quizes)
      ? quizes.filter(item => item?.start_date?.date === selectedDay)
      : [];

    return [...subjectEvents, ...meetingEvents, ...quizEvents];
  };

  onSelect = (value) => {
    if (!value) return;
    
    const selectedDay = value.format("YYYY-MM-DD");
    const selectedDateData = this.filterEventsByDate(selectedDay);
    
    this.setState({ 
      value, 
      selectedValue: value, 
      selectedDateData: selectedDateData || [],
      selectedDay 
    });
  };

  getEventsForDate = (selectedDay) => {
    return this.filterEventsByDate(selectedDay);
  };

  onPanelChange = (value) => {
    this.setState({ 
      value,
      selectedValue: value
    });
  };

  getListData = (value) => {
    // Get the current month and year from the value
    const valueMonth = value.format("YYYY-MM");
    const valueDate = value.date();
    
    // Only show events for the current month that has data
    const { daysData, meetings, quizes } = this.state;
    
    // Check if we have any data for this date
    const hasSubjectEvent = daysData?.some(item => {
      const eventDate = moment(item?.start_date?.date);
      return eventDate.format("YYYY-MM") === valueMonth && eventDate.date() === valueDate;
    });

    const hasMeetingEvent = meetings?.some(item => {
      const eventDate = moment(item?.date?.date);
      return eventDate.format("YYYY-MM") === valueMonth && eventDate.date() === valueDate;
    });

    const hasQuizEvent = quizes?.some(item => {
      const eventDate = moment(item?.start_date?.date);
      return eventDate.format("YYYY-MM") === valueMonth && eventDate.date() === valueDate;
    });

    // Return event data only if we have an event on this date
    if (hasSubjectEvent || hasMeetingEvent || hasQuizEvent) {
      return [{ type: "success", content: "Event" }];
    }

    return [];
  };

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.content} className="event-indicator" />
        ))}
      </ul>
    );
  };

  render() {
    const { 
      loading, 
      value, 
      selectedValue, 
      selectedDateData, 
      meetings, 
      quizes, 
      selectedDay 
    } = this.state;

    return (
      <div className="calendar-container">
        <Container className="py-3 studentcalender">
          <Spin spinning={loading}>
            <Row>
              <Col sm={8}>
                <Alert
                  message={`Selected date: ${selectedValue?.format("YYYY-MM-DD") || ''}`}
                  className="mb-3"
                />
                <Calendar
                  value={value}
                  onSelect={this.onSelect}
                  onPanelChange={this.onPanelChange}
                  dateCellRender={this.dateCellRender}
                  fullscreen={false}
                  disabled={loading}
                />
              </Col>
              <Col sm={4}>
                <CalenderDetails
                  details={selectedDateData || []}
                  meetings={meetings || []}
                  quizes={quizes || []}
                  selectedDay={selectedDay || ''}
                  loading={loading}
                />
              </Col>
            </Row>
          </Spin>
        </Container>
      </div>
    );
  }
}

export default ErrorHandler(CalendarComponent, axiosApiInstance);
