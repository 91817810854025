import React from "react";

class CalenderDetails extends React.Component {
  renderSubjectDetails = () => {
    const { details = [] } = this.props;

    if (!details?.length) return null;

    return details
      .filter(data => data?.subject) // Ensures only subject-related events are rendered
      .map((data) => (
        <div className="calenderBox my-3" style={styles.box} key={data?.id || Math.random()}>
          <p style={styles.text}>
            <strong>Subject: </strong>
            {data?.subject?.title}
          </p>
          <p style={styles.text}>
            <strong>Tutor: </strong>
            {data?.tutor?.full_name}
          </p>
          <p style={styles.text}>
            <strong>Start Time: </strong>
            {data?.start_time}
          </p>
          <p style={styles.text}>
            <strong>Start Date: </strong>
            {data?.start_date?.date || "No start date available"}
          </p>
          <p style={styles.text}>
            <strong>End Date: </strong>
            {data?.end_date?.date || "No end date available"}
          </p>
        </div>
      ));
  };
  renderMeetingDetails = () => {
    const { meetings = [], selectedDay } = this.props;
    if (!meetings?.length) return null;
    const filteredMeetings = meetings.filter(meeting => meeting?.date?.date === selectedDay);
    if (!filteredMeetings.length) return null;
    return filteredMeetings.map((meeting) => (
      <div className="calenderBox" style={styles.box} key={meeting.id}>
        <p style={styles.text}>
          <strong>Lecture Title: </strong>
          {meeting.title}
        </p>
        <p style={styles.text}>
          <strong>Lecture Zoom Link: </strong>
          <a href={meeting.link} target="_blank" rel="noopener noreferrer" style={styles.link}>
            Click Here
          </a>
        </p>
        <p style={styles.text}>
          <strong>Lecture Date: </strong>
          {meeting.date?.date || "No date available"}
        </p>
        <p style={styles.text}>
          <strong>Lecture Start Time: </strong>
          {meeting.start_time || "No start time available"}
        </p>
        <p style={styles.text}>
          <strong>Lecture Duration: </strong>
          {meeting.duration || "No duration available"}
        </p>
      </div>
    ));
  };
  renderQuizDetails = () => {
    const { quizes = [], selectedDay } = this.props;
    if (!quizes?.length) return null;
    const filteredQuizes = quizes.filter(quiz => quiz?.start_date?.date === selectedDay);
    if (!filteredQuizes.length) return null;
    return filteredQuizes.map((quiz) => (
      <div className="calenderBox" style={styles.box} key={quiz.id}>
        <p style={styles.text}>
          <strong>Quiz Title: </strong>
          {quiz.title}
        </p>
        <p style={styles.text}>
          <strong>Quiz Date: </strong>
          {quiz.start_date?.date || "No date available"}
        </p>
      </div>
    ));
  };
  render() {
    return (
      <div>
        <h3 style={styles.header}>Subjects</h3>
        <hr style={styles.hr} />
        {this.renderSubjectDetails()}

        <h3 style={styles.header}>Meetings</h3>
        <hr style={styles.hr} />
        {this.renderMeetingDetails()}

        <h3 style={styles.header}>Quizzes</h3>
        <hr style={styles.hr} />
        {this.renderQuizDetails()}
      </div>
    );
  }
}

const styles = {
  box: {
    backgroundColor: "#f4f4f4",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "15px",
  },
  text: {
    margin: "5px 0",
    color: "#333",
  },
  link: {
    color: "#1a73e8",
    textDecoration: "underline",
  },
  header: {
    color: "#124E68",
  },
  hr: {
    borderTop: "2px solid #124E68",
  },
};

export default CalenderDetails;

